import React, { Suspense, useState, useEffect, lazy } from 'react';
import {TalentCard} from './types';
import { useLocation, Location } from 'react-router-dom';

const TokenButtonLazy = lazy(() => import('./TokenButton'));

interface ExperienceDisplayProps {
  card: TalentCard;
  locationXP: string;
}

const ExperienceDisplay: React.FC<ExperienceDisplayProps> = ({ card, locationXP}) => {
  const [experience, setExperience] = useState<string | null>(null);
  const [force, setForce] = useState<string | null>(null); // Add state for force

  const shouldShowTokenButton = (locationXP.includes('team') || locationXP.includes('/my-talent-cards') || locationXP.includes('/my-talents') || locationXP.includes('dashboard'));


  useEffect(() => {
    const fetchData = async () => {
      try {

        setExperience(card.card_experience.toString() || 'Not available'); // Adjust according to the actual response structure
        setForce(card.card_force || 'Not available'); // Adjust according to the actual response structure
        
      } catch (error) {
        console.error("Error fetching data: ", error);
        setExperience('Error fetching experience.');
        setForce('Error fetching force.');
      }
    };

    fetchData();
  }, [card]); // Dependencies array, re-fetch if these values change

  const token = {
    card: card,
  };

  let classNameXP = '';

  if (locationXP.includes('team') || locationXP.includes('/my-talent-cards')  || locationXP.includes('/my-talents') || locationXP.includes('dashboard')) {
    classNameXP = 'general_tabs';
  } else if (locationXP.includes("/profile")) {
    classNameXP = 'general_tabs_profile';
  }

  return (
    <span className={classNameXP}>
    <span className="force_market">
      <i 
        style={{ 
          fontSize: '70%', 
          position: 'relative', 
          top: '-1px' 
        }}  
        className="fa-solid fa-shield-halved"
      ></i>&nbsp;
      {force}
    </span>
    <span className="xp_market">
      <i 
        style={{ 
          fontSize: '70%', 
          position: 'relative', 
          top: '-1px' 
        }}  
        className="fa-solid fa-bolt"
      ></i>&nbsp;
      {experience}
    </span>
    {shouldShowTokenButton && (
    <Suspense fallback={<div>Loading...</div>}>
          <TokenButtonLazy 
          card={card}
           /></Suspense>
    
      )}
    </span>
  );
};

export default ExperienceDisplay;
